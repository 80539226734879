// extracted by mini-css-extract-plugin
export var Action = "AnsweringServices-module--Action--hEyib";
export var Benefits = "AnsweringServices-module--Benefits--sLWYy";
export var Content = "AnsweringServices-module--Content--OiL0D";
export var Greeting = "AnsweringServices-module--Greeting--Ya5Hb";
export var Intro = "AnsweringServices-module--Intro--UPxk+";
export var Item = "AnsweringServices-module--Item--y4wZH";
export var Line = "AnsweringServices-module--Line--Vs0pY";
export var Opener = "AnsweringServices-module--Opener--g+K6+";
export var PlayCard = "AnsweringServices-module--PlayCard--Rw4DB";
export var Side = "AnsweringServices-module--Side--6TyFi";
export var Video = "AnsweringServices-module--Video--M+g3R";