import React, {MutableRefObject, useRef, useState, Fragment} from "react";
import { Helmet } from 'react-helmet';
import Button from "../../Button/Button";
import Spotlight from "../Home/Spotlight/Spotlight";
import Footer from "../../Footer/Footer";
import Modal from "../../Modal/Modal";
import {InlineWidget} from "react-calendly";

const styles = require("./AnsweringServices.module.scss");

interface Props {}

const AnsweringServices: React.FunctionComponent<Props> = ({data, pageContext}) => {
    const videoRef: MutableRefObject<HTMLVideoElement|null> = useRef(null);
    const [showScheduleCallModal, setShowScheduleCallModal] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false);
    const [videoStarted, setVideoStarted] = useState(false);

    function changePlayState(event) {
        event.preventDefault();
        if (!videoRef.current) {
            return;
        }

        if (!videoStarted) {
            setVideoStarted(true);
        }

        if (videoRef.current.paused === true) {
            setIsPlaying(true);
            videoRef.current.setAttribute("controls","controls");
            videoRef.current.play();

        } else {
            setIsPlaying(false);
            videoRef.current.removeAttribute("controls");
            videoRef.current.pause();
        }
    }

    return (
        <Fragment>
            <Helmet>
                <title>Prestavi | Call Answering Services</title>
            </Helmet>
        <div className={styles.Intro}>
            <div className={styles.Greeting}>
                <div className={styles.Content}>
                    <div className={styles.Opener}>
                        <h1>Meet Prestavi, the platform proven to help answering services run so much smoother.</h1>
                        <p>Agents are the beating heart of your operation. Let's make their job easier than ever.</p>
                        <Button onClick={() => setShowScheduleCallModal(true)} theme="solid" tone="medium" color="primary" size="large">Schedule a 20-minute Exploratory Call</Button>
                    </div>
                    <div className={styles.Side}>
                        <h4>With Prestavi</h4>
                        <div className={styles.Benefits}>
                            <div className={styles.Line}></div>
                            <div className={styles.Item}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26.432" height="26.432" viewBox="0 0 26.432 26.432">
                                    <g transform="translate(-711 -1424)">
                                        <path d="M0,13.216A13.216,13.216,0,1,0,13.216,0,13.216,13.216,0,0,0,0,13.216" transform="translate(711 1424)" fill="#b9e5d6"/>
                                        <path d="M11,19.552a1.245,1.245,0,0,1-.79-.283L5.2,15.145a1.245,1.245,0,0,1,1.581-1.923l4.11,3.38L19.53,7.278A1.245,1.245,0,0,1,21.356,8.97L11.917,19.153a1.241,1.241,0,0,1-.913.4" transform="translate(711 1424)" fill="#2a9672"/>
                                    </g>
                                </svg>
                                <p>
                                    Impossible for agents to make mistakes.
                                </p>
                            </div>
                            <div className={styles.Item}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26.432" height="26.432" viewBox="0 0 26.432 26.432">
                                    <g transform="translate(-711 -1424)">
                                        <path d="M0,13.216A13.216,13.216,0,1,0,13.216,0,13.216,13.216,0,0,0,0,13.216" transform="translate(711 1424)" fill="#b9e5d6"/>
                                        <path d="M11,19.552a1.245,1.245,0,0,1-.79-.283L5.2,15.145a1.245,1.245,0,0,1,1.581-1.923l4.11,3.38L19.53,7.278A1.245,1.245,0,0,1,21.356,8.97L11.917,19.153a1.241,1.241,0,0,1-.913.4" transform="translate(711 1424)" fill="#2a9672"/>
                                    </g>
                                </svg>
                                <p>
                                    Agents are empowered with the information they need, exactly when they need it.
                                </p>
                            </div>
                            <div className={styles.Item}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26.432" height="26.432" viewBox="0 0 26.432 26.432">
                                    <g transform="translate(-711 -1424)">
                                        <path d="M0,13.216A13.216,13.216,0,1,0,13.216,0,13.216,13.216,0,0,0,0,13.216" transform="translate(711 1424)" fill="#b9e5d6"/>
                                        <path d="M11,19.552a1.245,1.245,0,0,1-.79-.283L5.2,15.145a1.245,1.245,0,0,1,1.581-1.923l4.11,3.38L19.53,7.278A1.245,1.245,0,0,1,21.356,8.97L11.917,19.153a1.241,1.241,0,0,1-.913.4" transform="translate(711 1424)" fill="#2a9672"/>
                                    </g>
                                </svg>
                                <p>
                                    Confidence that your team provides an exceptional experience to every caller.
                                </p>
                            </div>
                            <div className={styles.Item}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26.432" height="26.432" viewBox="0 0 26.432 26.432">
                                    <g transform="translate(-711 -1424)">
                                        <path d="M0,13.216A13.216,13.216,0,1,0,13.216,0,13.216,13.216,0,0,0,0,13.216" transform="translate(711 1424)" fill="#b9e5d6"/>
                                        <path d="M11,19.552a1.245,1.245,0,0,1-.79-.283L5.2,15.145a1.245,1.245,0,0,1,1.581-1.923l4.11,3.38L19.53,7.278A1.245,1.245,0,0,1,21.356,8.97L11.917,19.153a1.241,1.241,0,0,1-.913.4" transform="translate(711 1424)" fill="#2a9672"/>
                                    </g>
                                </svg>
                                <p>
                                    Training new agents is simple and fast.
                                </p>
                            </div>
                            <div className={styles.Item}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26.432" height="26.432" viewBox="0 0 26.432 26.432">
                                    <g transform="translate(-711 -1424)">
                                        <path d="M0,13.216A13.216,13.216,0,1,0,13.216,0,13.216,13.216,0,0,0,0,13.216" transform="translate(711 1424)" fill="#b9e5d6"/>
                                        <path d="M11,19.552a1.245,1.245,0,0,1-.79-.283L5.2,15.145a1.245,1.245,0,0,1,1.581-1.923l4.11,3.38L19.53,7.278A1.245,1.245,0,0,1,21.356,8.97L11.917,19.153a1.241,1.241,0,0,1-.913.4" transform="translate(711 1424)" fill="#2a9672"/>
                                    </g>
                                </svg>
                                <p>
                                    Expand your offering and do more than simply "Take a Message".
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.Video}>
                <div className={styles.Content}>
                    {!isPlaying && (
                        <div className={styles.PlayCard}>
                            <div className={styles.PlayBtn} onClick={changePlayState}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58">
                                    <g transform="translate(-173 -1051)">
                                        <circle cx="29" cy="29" r="29" transform="translate(173 1051)" fill="#d9e4fc"/>
                                        <path d="M.5,2.926V26.634a2.423,2.423,0,0,0,3.648,2.09L24.376,16.87a2.422,2.422,0,0,0,0-4.18L4.148.836A2.423,2.423,0,0,0,.5,2.926Z" transform="translate(191 1065.22)" fill="#265dde"/>
                                    </g>
                                </svg>
                            </div>
                            <div>
                                <p>I'd love to show you in less than two minutes how we could help. <span onClick={changePlayState}>Watch Now</span></p>
                            </div>
                        </div>
                    )}
                    <video playsInline={true} muted={false} ref={videoRef} onClick={changePlayState} autoPlay={false} disablePictureInPicture={true} controlsList={"nodownload noplaybackrate"} controls={false} loop={false} width="100%" src={`https://cdn.prestavi.com/prospecting/answering-service/videos/call-answering-services.mp4`} />
                </div>
            </div>
            <Spotlight hideHeading={true} />
            <Footer />

            <Modal show={showScheduleCallModal} toggleModal={() => setShowScheduleCallModal(!showScheduleCallModal)}>
                <div style={{minHeight:'850px'}}>
                    <InlineWidget styles={{height:"850px"}} url="https://calendly.com/prestavi/introduction?text_color=273659&primary_color=265dde" />
                </div>
            </Modal>

        </div>
        </Fragment>
    );
};


export default AnsweringServices;
